"use client";

import React, { FC } from "react";
import Image from "next/image";

interface Block {
  imageSrc?: string;
  subheading: string;
  desc: string;
}

interface LaunchYourProjectProps {
  title: string;
  description: string;
  blocks: Block[];
  imageSrc?: string;
  conclusion?: string;
}

const LaunchYourProject: FC<LaunchYourProjectProps> = ({
  title,
  description,
  blocks,
  imageSrc = "/images/launchyourproject/web-development-project.png",
  conclusion = "",
}) => {
  return (
    <section className="bg-color-brown-200 pt-10 relative overflow-hidden">
      <div className="max-w-screen-lg mx-auto">
        <h2 className="text-center font-bold mb-2 mx-6 md:mx-auto">{title}</h2>
        <p className="text-center">{description}</p>
      </div>
      <div className="max-w-screen-2xl mx-auto mt-2 md:mt-4 pt-2 md:pt-10">
        <div className="flex flex-col z-20 md:hidden px-5">
          {blocks.map((item, index) => (
            <div key={index} className={`pt-3`}>
              <div className="flex rounded-sm mb-5 shadow-xl p-5 bg-white border-2">
                {item.imageSrc && (
                  <Image
                    width={40}
                    height={40}
                    src={item.imageSrc}
                    alt=""
                    className="object-contain w-20 hidden md:flex"
                  />
                )}
                <div className="font-sans">
                  <h3 className="text-color-blue-800 mb-3">
                    {item.subheading}
                  </h3>
                  <p className="-mt-2 text-base">{item.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="md:grid grid-cols-1 md:grid-cols-8 z-20 hidden relative">
          <Image
            src={imageSrc}
            alt={title}
            width={400}
            height={500}
            className="absolute object-contain hidden"
          />
          {blocks.map((item, index) => (
            <div
              key={index}
              className={`col-span-3 pt-3 ${
                index % 2 === 0 ? "col-start-3 mr-10" : "col-start-0 ml-10"
              } z-20 ${index == 2 ? "-ml-10 mr-20" : ""}  ${
                index == 3 ? "ml-20 -mr-10" : ""
              }`}
            >
              <div className="flex gap-5 rounded-xl mb-5 shadow-xl p-5 bg-white border-2">
                {item.imageSrc && (
                  <Image
                    width={40}
                    height={40}
                    src={item.imageSrc}
                    alt=""
                    className="object-contain w-20"
                  />
                )}
                <div className="font-sans">
                  <h3 className="text-2xl text-color-blue-800 mb-3 whitespace-nowrap">
                    {item.subheading}
                  </h3>
                  <p className="-mt-2 text-base">{item.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="relative md:absolute bottom-0 min-h-[30rem] md:min-h-[40rem] min-w-72 md:min-w-[30rem]">
        <Image
          src="/images/launchyourproject/web-development-project.png"
          alt={title}
          fill
          className="absolute bottom-0 object-cover z-10"
        />
      </div>
    </section>
  );
};

export default LaunchYourProject;
