"use client";
import { Accordion } from "flowbite-react";
import { motion } from "framer-motion";
import Image from "next/image";
import { useState } from "react";
import { childVariants, parentVariants } from "../constants/motion";

const defaultIcon = "/images/logos/react-js-logo.png";
const defaultBgImage = "/images/default-background.jpg"; // Replace this with your actual default image path

const Technologies = ({
  caption,
  title,
  description,
  accordionBlocks,
  bg_image = "/images/technologies/about-us.jpg",
  bg_mobile_image = "",
}) => {
  const [bgImageSrc, setBgImageSrc] = useState(bg_image);

  const handleImageError = () => {
    setBgImageSrc(defaultBgImage);
  };

  if (!bg_mobile_image) {
    bg_mobile_image = bg_image;
  }

  return (
    <motion.section
      variants={parentVariants}
      initial="initial"
      whileInView="animate"
      className="section bg-color-light-blue-200"
    >
      <div className="overflow-hidden flex flex-col justify-center items-center align-middle py-10">
        <div className="mx-auto max-w-screen-xl px-4 lg:px-0 justify-start items-start">
          <div className="mx-auto my-auto flex flex-col md:flex-row gap-x-4 sm:gap-y-20 lg:mx-0 lg:max-w-none ">
            <div className="lg:pr-8 lg:pt-4 flex-1 basis-3/5">
              <div>
                <motion.h2
                  variants={childVariants}
                  className="mb-3 font-bold tracking-tight text-color-blue-800"
                >
                  {title}
                </motion.h2>
                <motion.p
                  variants={childVariants}
                  className="text-md leading-7 text-gray-700"
                >
                  {description}
                </motion.p>
                <motion.div variants={childVariants}>
                  <Accordion className="accordion-benefits">
                    {accordionBlocks.map((block, index) => (
                      <Accordion.Panel key={index}>
                        <Accordion.Title>
                          <Image
                            src={block.icon || defaultIcon}
                            className="w-12 pl-4 mr-2 inline-block"
                            width={400}
                            height={400}
                            alt={`${block?.title || "Default"} logo`}
                          />
                          <span className="pl-10">{block.title}</span>
                        </Accordion.Title>
                        <Accordion.Content className="accordion-content">
                          <p
                            className="mb-2 text-md"
                            dangerouslySetInnerHTML={{ __html: block.content }}
                          ></p>
                        </Accordion.Content>
                      </Accordion.Panel>
                    ))}
                  </Accordion>
                </motion.div>
              </div>
            </div>
            <motion.div
              variants={childVariants}
              className="flex-1 basis-2/5 bg-orange-300 relative"
            >
              <Image
                src={bgImageSrc}
                alt={title}
                fill
                sizes="100vw"
                className="z-50 absolute w-full h-full object-cover"
                priority={false}
                onError={handleImageError} // Fallback to default image on error
              />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Technologies;
